.create_movements {
  width: 90%;
  margin: 0 auto;
}
.mv-5 {
  grid-area: mv5;
}
.mv-6 {
  grid-area: mv6;
}
.mv-7 {
  grid-area: mv7;
}

.mv-8 {
  grid-area: mv8;
}



.create_movements {
  display: grid;
  grid-template-areas: "mv5 mv6"
                       "mv7 mv8";
  gap: 10px;
}

input.inputDataEdit{
  width: 100px !important;
}



.containerMovementsProduct {
  width: 95%;
  margin: 0 auto;
  padding-right: 4%;
}


.pdc-1{
  grid-area: pd1;
}
.pdc-2{
  grid-area: pd2;
}
.pdc-3{
  grid-area: pd3;
}
.pdc-4{
  grid-area: pd4;

}
.pdc-5{
  grid-area: pd5;

}
.sdbts-1{
  grid-area: pd1;
  margin-top: 20px;

}
.sdbts-2{
  grid-area: pd2;
  margin-top: 20px;

}
.sdbts-3{
  grid-area: pd3;
  margin-top: 15px;

}
.sdbts-4{
  grid-area: pd4;
  margin-top: 15px !important;
}
.sdbts-5{
  grid-area: pd5;
  margin-top: 20px !important;
}
.sdbts-6{
  grid-area: pd6;
  margin-top: 20px;
}
.containersaledebts{
  width: 95%;
  margin: 0 auto;
  padding-right: 4%;
}

.containersaledebts{
  display: grid;
  grid-template-areas:         
                  "pd1 pd2 pd3 pd4 pd5 pd6";
  gap: 10px;
  position: absolute;
  margin: 0 auto;
  width: 80%;
  margin-left: 3%;
  z-index: 10;
}

.containerMovementsProduct {
  display: grid;
  grid-template-areas:         
                  "pd1 pd2 pd3 pd4";
  gap: 10px;
  position: absolute;
  margin: 0 auto;
  width: 80%;
  margin-left: 3%;
  margin-top: 20px;
}

.containerMovementsProduct2{
  width: 95%;
  margin: 0 auto;
  padding-right: 4%;
}

.containerMovementsProduct2 {
  display: grid;
  grid-template-areas:         
                  "pd1 pd2 pd3 pd4";
  gap: 10px;
  margin: 0 auto;
  width: 80%;
}
.containerMovementsProduct2 button{
  margin-top: 12px;
}
.existProduct{
  padding-top: 12px;
}

.containerStock {
  width: 95%;
  margin: 0 auto;
  padding-left: 1%;
}
.containerStock {
  display: grid;
  position: absolute;
  grid-template-areas:         
                  "pd1 pd2 pd3 pd4";
  gap: 10px;
}

.dt-1{
  grid-area: dt1;
}
.dt-2{
  grid-area: dt2;
}
.dt-3{
  grid-area: dt3;
}
.dt-4{
  grid-area: dt4;
}
.dt-5{
  grid-area: dt5;
}
.dt-6{
  grid-area: dt6;
}



.containerDetail {
  width: 95%;
  margin: 0 auto;
  padding-left: 1%;
}
.containerDetail {
  display: grid;
  position: relative;
  grid-template-areas:         
                  "dt1 dt2 dt3 dt4"
                  "dt5 dt5 dt6 dt7";
  gap: 10px;
}

.titleModul {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.Table_Movements_Product{
  margin: 0 auto;
  width: 95%;
}

.Table_Movements_Product thead th:nth-child(1){
  width: 100px !important;
  max-width: 100px !important;
  text-align: center;
}

.Table_Movements_Product tr td:nth-child(1) img{

  text-align: center;
}