
.containerselectfrezz{
    margin: 0 auto;
    display: grid;
    grid-template-columns: 20% 15% 15% 60%;
    grid-template-areas:"a b c d";
    grid-gap: 10px;
    width: 100%;
    height: 22px;
    position: relative;
    z-index: 1;
}
.TableCongeladores{
    margin-top: -18px !important;
}
.tablecongprinc{
    margin-top: -60px;
}
.selectfres{
    width: 100%;
    grid-area: a;
    margin-top: 20px; 
}
.selectfres select{
    font-size: 15px;
    padding: 0px;
    padding-left: 10px;
    height: 45px;
}
.selectfres2{
    grid-area: b;
    margin-top: 25px;
}
.selectfres2 .button-cancel{
    background-color: red;
    border-radius: 5px;
    margin-top: 5px;
    height: 48px;
}
.selectfres2 button{
    width: 80%;
    height: 48px;
    font-size: 17px;
}
.selectfres3{
    grid-area: c;
    margin-top: 25px;
}
.selectfres3 button{
    width: 100%;
    font-size: 17px;
    height: 48px;
}

.button-confirm{
    background-color: #11AC09;
    margin-top: 5px;
    border-radius: 5px;
}
.TableCongeladores .containerCutBox{
    margin-top: 36px;
}
.TableCongeladores .titletable h1{
    font-size: 40px;
}

.TableCongeladores .sticky-table-table{
   width: 100%;
   font-size: 15px;
}
.TableCongeladores{
    margin: 0 auto;
    width: 95% !important;
}
.TableCongeladores .titletable{
    margin-top: -30px;
}

.TableCongeladores th:nth-child(1) img{
    width: 35px !important;
    height: 35px !important;
}
.TableCongeladores td:nth-child(1){
    min-width: 70px !important;
}

.TableCongeladores td:nth-child(2){
    white-space: normal;
}
.TableCongeladores td:nth-child(2) img:hover{
    cursor: pointer;
}
.TableCongeladores td:nth-child(2){
    min-width: 120px;
}

.TableCongeladores th:nth-child(3){
    white-space: normal;
    max-width: 120px;
}

.TableCongeladores td:nth-child(3),
.TableCongeladores td:nth-child(4),
.TableCongeladores td:nth-child(5),
.TableCongeladores td:nth-child(6),
.TableCongeladores td:nth-child(7),
.TableCongeladores td:nth-child(8){
    text-align: center;
}
.TableCongeladores td:nth-child(9){
    max-width: 150px;
    white-space: normal;
    text-align: justify;
}
.TableCongeladores td:nth-child(10){
    white-space: normal;
}

.TableCongeladores td:nth-child(10),
.TableCongeladores td:nth-child(11){
    text-align: center;
}
.TableCongeladores td:nth-child(12){
    text-align: left;
}
.TableCongeladores td:nth-child(13){
    text-align: center;
}
.TableCongeladores td:nth-child(14){
    text-align: left;
}

.TableCongeladores td:nth-child(15){
    text-align: center;
}

.form-frezz{
 font-size: 20px;
}
.form-frezz label{
    font-size: 22px;
    color: black;
}
.form-frezz input{
    height: 60px;
    font-size: 18px;
}
.gridfrez1{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-template-areas:"a a b b c c";
    grid-gap: 10px;
}

.frez1{grid-area:a;}
.frez2{grid-area:b;}
.frez3{grid-area:c;}

.gridfrez2{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-template-areas:"a a b b c c";
    grid-gap: 10px;
}

.frez4{
    grid-area:a;
    position: relative;
    margin-left: 10px;
    margin-top: 12px;
} 
.frez4 label{
    position: absolute;
    top: 0;
    margin-top: -10px;
    margin-left: 10px;
    background-color: white;
    font-size: 17px;
}
.frez5{grid-area:b;}
.frez6{grid-area:c;}

.gridfrez3{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 13% 17% 17% 15% 13%;
    grid-template-areas:"a a b b c c";
    grid-gap: 10px;
}
.frez7{grid-area: a;}
.frez8{grid-area: b;}
.frez9{grid-area: c;}
.frezn0{grid-area: n;}


.gridfrez4{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-template-areas:"n n a a a a";
    grid-gap: 10px;
}

.frez10{grid-area: a;}
.frez11{grid-area: b;}
.frez12{grid-area: c;}
.frez13{grid-area: d;}


.iconos-frez{
    width: 25px;
    height: 25px;
    margin: 2px;
}

/* movimiento de almance */
.waremovfrezz{
    margin-top: -20px;
}
.grid-mov-almacen{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 100%;
    grid-template-areas:
        "a"
        "b"
        "c";
    grid-gap: 10px;
}   
.mov-almac-1{grid-area: a;}
.mov-almac-2{grid-area: b; margin-right: 10px;}
.mov-almac-3{grid-area: c; margin-right: 10px;}
.puntoventafrezz{
    margin-left: 12px;
}
.puntoventafrezz input {
    height: 50px !important;
}
.mov-almac-3 input{
    height: 60px;
}


.subtablehistoryfrez .sticky-table-table{
    width: 100%;
    font-size: 15px;
}
.subtablehistoryfrez {
    width: 60% !important;
    margin: 0 0;
}
.subtablehistoryfrez{
    text-align: center;
}

.subtablehistoryfrez th:nth-child(1){
    text-align: center;
    white-space: normal;
    max-width: 80px !important;
}
.subtablehistoryfrez td:nth-child(2){
    max-width: 100px;
    white-space: normal;
    text-align: center;
}
.subtablehistoryfrez td:nth-child(3){
    text-align: left;
}
.subtablehistoryfrez td:nth-child(4){
    text-align: left;
    white-space: normal;
    max-width: 100px;
}
.subtablehistoryfrez td:nth-child(5){
    text-align: left;
    white-space: normal;
    max-width: 100px;
}
.subtablehistoryfrez td:nth-child(6){
    text-align: left;
    white-space: normal;
    max-width: 100px;
}


/* detalles del congelador */
.grid-details-freez{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 45% 45%;
    grid-template-areas:
        "a b""c d""e f""g h""i j";
}
.bitac-frez{
    padding-top: 20px;
    font-size: 25px;
    color: black;
    text-align: left;
    margin: 0 auto;
    width: 90%;
    font-weight: bold;
}
.textareapuntosventa{
    font-size: 20px;
}

.grid-details-freez p{
    text-align: left;
    height: 17px;
}
.grid-details-freez .t{
    font-size: 20px;
    font-weight: bold;
    color: black;
}
.grid-details-freez .d{
    font-size: 20px;
    color: black;
}

.det-frez-1{grid-area: a;}
.det-frez-1a{grid-area: b;}
.det-frez-2{grid-area: c;}
.det-frez-2a{grid-area: d;}
.det-frez-3{grid-area: e;}
.det-frez-3a{grid-area: f;}
.det-frez-4{grid-area: g;}
.det-frez-4a{grid-area: h;}
.det-frez-5{grid-area: i;}
.det-frez-5a{grid-area: j;}



.grid-mov-almacen label{
    font-size: 20px;
}
/* modal para confirmar descapitalizacion de congeladors */
.descripcion-desc-confirm{
    width: 85%;
    margin: 0 auto;
}
.descripcion-desc-confirm p{
    font-size: 20px;
    text-align: justify;
    height: 10px;
    margin-left: 15px;
}
.container-confirm-desc .nota-desc{
    width: 85%;
    margin: 0 auto;
}
.nota-desc input{
    height: 50px;
    font-size: 20px;
}
.nota-desc label{
    font-size: 20px;
}
.b-p{
    font-size: 20px;
    font-weight:bold;
    color: black;
}

.checktableconge{
    background-color: white;
    color: white;
    height: 30px;
    width: 30px;
    padding: 0;
    margin: 0 auto;
}   
.checktableconge span{
    width: 30px;
    height: 30px;
}

/* MODULO DE CONSULTA DE MOVIMIENTOS DE ALMACENES DE CONGELADORES */
.TableCongeladores-warehouse .containerCutBox{
    margin-top: 36px;
}
.TableCongeladores-warehouse .titletable h1{
    font-size: 40px;
}

.TableCongeladores-warehouse .sticky-table-table{
   width: 100%;
   font-size: 15px;
}
.TableCongeladores-warehouse{
    margin: 0 auto;
    width: 95% !important;
}
.TableCongeladores-warehouse .titletable{
    margin-top: -30px;
}
.TableCongeladores-warehouse td:nth-child(1),
.TableCongeladores-warehouse td:nth-child(2),
.TableCongeladores-warehouse td:nth-child(3),
.TableCongeladores-warehouse td:nth-child(4){
    text-align: center;
}

.TableCongeladores-warehouse td:nth-child(5){
    text-align: left;
}
.TableCongeladores-warehouse td:nth-child(6){
    text-align: center;
}
.TableCongeladores-warehouse td:nth-child(7){
    text-align: left;
}


.search-mov-frz-war{
    margin: 0 auto;
    display: grid;
    grid-template-columns: 20% 12% 12% 12%;
    grid-template-areas:"a b c d";
    grid-gap: 10px;
    width: 100%;
    height: 22px;
    position: relative;
    z-index: 1;
}
.smfw1{grid-area: a;}
.smfw1 select{
    height: 48px;
    padding: 0px;
    padding-left: 10px;
}
.smfw2{grid-area: b;}
.smfw3{grid-area: c;}
.smfw2 input, .smfw3 input{
    margin-left: 10px;
    margin-top: 12px;
    height: 48px;
}
.smfw4{grid-area: d;}
.smfw4 button{
    height: 48px;
    margin-top: 12px;
    font-size: 17px;
}