.css-b62m3t-container {
  width: auto !important;
}
.css-3w2yfm-ValueContainer {
  height: 60px !important;
  overflow-y: scroll !important;
}

.table-invoice-relacion{
  width: 95% !important;
  margin: 0 auto;
}
.table-invoice-relacion .sticky-table-table{
    width: 100%;
}
.modal-mapa-rel{
  min-width: 80% !important;
  height: 90vh !important;
}
.mapa_icono{
  width: 20px;
}





.fullscreen {
  background-color: white;
  width: 100%;
  height: 100%;
}
