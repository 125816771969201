.TableConsultCases{
  margin: 0 auto;
  width: 95% !important;
  text-align: center;
}

.tableFixHead{
  overflow: scroll !important;
}

.items-consult-cases{
  margin: 0 auto;
  display: grid;
  margin-left: 0%;
  align-items: flex-end;
  width: 75%;
  grid-template-columns: 40% 20%;
  grid-template-rows: auto;
  grid-template-areas:
  "select filters";
  justify-content: space-between;
  grid-gap:10px;
  z-index: 10;
  position: relative;
  margin-top: -10px;
  margin-bottom: -30px;  
}

.input-filter{
  grid-area: select;
  font-size: small;
}

.breadcrumbs-general {
  margin-left: 0%;
  text-align: left;
  margin: 0 auto;
  width: 90%;
  font-size: 20px;
  margin-bottom: 20px;
}
.breadcrumbs-general span {
  color: gray;
  padding-left: 10px;
  padding-right: 10px;
}

.breadcrumbs-general a{
  text-decoration: none;
  color: black !important;
  padding-left: 15px;
  padding-right: 15px;
}

.breadcrumbs-general a:hover {
  text-decoration: underline;
  background-color: rgb(26, 55, 77);
  color: white !important;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  cursor: pointer;
}

.switch-filter{
  grid-area: filters;
  justify-content: center;
}

.toLeft{
  text-align: left;
  width: fit-content;
}

.adjust {
  width: fit-content;
  text-align: center;
  align-items: center;
  justify-content: center;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.rotating {
  animation: rotating 320ms linear infinite;
}
.button-tour-smit{
  background-color: #fbc94b;
  color:black;
  font-weight:bold; 
  height: 35px;
  width: 170px;
  font-size: 15px;
  border-radius: 5px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -40px;
  margin-right: -33%;
}
.breadcrumbs-general{
  z-index: 10 !important;
}

.carrusel-container {
  width: 100%;
  overflow-x: auto;
}

.carrusel-img {
  display: flex; 
  overflow-x: auto;
}

.img-support {
  margin-right: 10px; 
  flex-shrink: 0; 
}
.img-support img{
  width: 300px !important;
}
.img-support img:hover{
  cursor: pointer;
}


.img-ticket{
  width: 100%;
  margin: 0 auto;
}
.carousel-container{
  width: 100%;
  position: relative;
}
.back-img{
  position: absolute;
  z-index: 150;
  top: 0;
  left: 0;
  background-color: rgba(48, 48, 48, 0.564);
  margin-top: 22%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.back-img svg{
  font-size: 50px !important;
  color: white;
}
.back-img:hover{cursor: pointer;}
.next-img:hover{cursor: pointer;}
.next-img svg{
  font-size: 50px !important;
  color: white;
}
.next-img{
  position: absolute;
  z-index: 150;
  top: 0;
  right: 0;
  background-color: rgba(48, 48, 48, 0.564);
  margin-top: 22%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.TableConsultCases th:nth-child(1){
  width: 30px !important;
  min-width: 30px !important;
}
.TableConsultCases td:nth-child(2),
.TableConsultCases td:nth-child(3){
  width: 100px !important;
  min-width: 100px !important;
  white-space: normal;
  word-wrap: break-word;
}


.TableConsultCases th:nth-child(4),
.TableConsultCases th:nth-child(5),
.TableConsultCases th:nth-child(6),
.TableConsultCases th:nth-child(7),
.TableConsultCases th:nth-child(8),
.TableConsultCases th:nth-child(9),
.TableConsultCases th:nth-child(10),
.TableConsultCases th:nth-child(11),
.TableConsultCases th:nth-child(12){
  width: 70px !important;
  min-width: 70px !important;
  white-space: normal;
  word-wrap: break-word;
}

.TableConsultCases td:nth-child(4),
.TableConsultCases td:nth-child(5),
.TableConsultCases td:nth-child(6),
.TableConsultCases td:nth-child(7),
.TableConsultCases td:nth-child(8),
.TableConsultCases td:nth-child(9),
.TableConsultCases td:nth-child(10),
.TableConsultCases td:nth-child(11),
.TableConsultCases td:nth-child(12){
  white-space: normal;
  word-wrap: break-word;
}


.email-elips{
  width: 120px !important;
  min-width: 120px !important;
  max-width: 150px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}