
.file-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .file-upload-container h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .drop-area {
    width: 100%;
    max-width: 100%;
    height: auto;
    border: 2px dashed #ccc;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    cursor: pointer;
    margin-bottom: 5px;
    transition: border-color 0.3s, background-color 0.3s;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .drop-area.highlight {
    border-color: #007bff;
    background-color: #eaf4ff;
  }
  
  .icon-container_down {
    margin-bottom: 10px;
    width: 38px;
    height: 38px;
    color: #007bff;
  }
  
  .icon-upload {
    width: 38px;
    height: 38px;
    color: #007bff;
  }
  
  .file-upload-container p {
    margin: 5px 0;
    font-size: 0.9rem;
    color: #555;
    font-size: 1.1rem;
    font-weight: bold;
  }
  
  .description-text {
    margin: 10px 0;
    font-size: 0.85rem;
    color: #777;
    text-align: center;
  }
  
  .download-template-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    text-transform: uppercase;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    margin-top: 20px;
    
  }
  
  .download-template-button:hover {
    background-color: #0056b3;
  }
  
  .icon-download {
    width: 20px;
    height: 20px;
  }
  
  
  .info-box {
    display: flex;
    align-items: center;
    justify-content: center; /* Centra los ítems en el eje horizontal */
    gap: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px 15px;
    margin-top: 15px;
    width: 100%; /* Asegúrate de que ocupa todo el ancho */
    max-width: 100%;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .info-icon {
    width: 24px;
    height: 24px;
    color: #007bff;
  }
  
  .info-box p {
    font-size: 1.1rem;
    font-weight: bold;
    color: #555;
    margin: 0;
    
  }
  
  
  .download-template-button {
    display: flex;
    align-items: center;
    justify-content: center; /* Asegura que el contenido esté centrado horizontalmente */
    gap: 8px;
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    text-transform: uppercase;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
  }
.content-file-upload-cabinets{
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
}   

.upload_xml_pdf_order{
    display: grid;
    grid-template-columns: 45% 45%;
    grid-template-areas: "a b";
    grid-gap: 10%;
    width: 100%;
    margin: 0 auto;
}
.icon-upload-xmlpdf{
    padding-bottom: 10px;
}
.icon-upload-xmlpdf img{
    height: 60px !important;
    width: 50px !important;
}
.text-upload-file-xmlpdf{
    font-size: 20px;
    text-align: left;
}
.container-upload-xmlpdf{
    width: 50%;
    margin: 0 auto;
}
.upload_smlpdf-content-botton{
    width: 100%;
    margin: 0 auto; 
    margin-top: 20px;
}
.upload_smlpdf-content-botton button{
    width: 100%;
    text-align: center;
    height: auto;
    border: none;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border-radius: 10px;
    font-size: 18px;
}


.upload-relation-orderinvoice{
  display: grid;
  grid-template-columns: 45% 45%;
  grid-area: "a b";
  grid-gap: 10%;
  margin: 0 auto;
  margin-top: 20px;
}
.upload-relation-orderinvoice button{
  width: 100%;
  font-size: 18px;
  text-align: center;
  border: none;
  border-radius: 10px;
}
.boton-create-order-crud{
  width: 100px;
}
.boton-create-order-crud img{
  width: 35px;
}

.sections-form-crud{
  width: 50%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 33% 34% 33%;
  grid-template-areas: "a b c";
}
.sections-form-crud div{
  border: 1px solid gray;
  border-radius: 10px;
  border-bottom: none;
  padding-top: 10px;
  cursor: pointer;
}
.sections-form-items{
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.grid-form-order-1{
  width: 95%;
  text-align: center;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-areas: 
  "a a a a a"
  "b b c c c";
  grid-gap: 8px;
}

.gfi0{
  grid-area: z;
  padding-top: 10px;
}
.gfi1{grid-area: a;}
.gfi2{grid-area: b;}
.gfi3{grid-area: c;}

.gfi4{grid-area: a;}
.gfi5{grid-area: a;}
.gfi6{grid-area: b;}
.gfi7{grid-area: c;}
.gfi8{grid-area: a;}
.gfi9{grid-area: b;}
.gfi10{grid-area: c;}


.grid-form-order-2{
  width: 95%;
  text-align: center;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-areas: "a a a a a";
  grid-gap: 8px;
}
.grid-form-order-3{
  width: 95%;
  text-align: center;
  display: grid;
  grid-template-columns: 17% 16% 17% 16% 17% 16%;
  grid-template-areas: "a a b b c c";
  grid-gap: 8px;
}
.gfi2 input{
  padding-top: 5px;
  padding-bottom: 5px;
}

.grid-form-order-4{
  width: 95%;
  text-align: center;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-areas: "b b c a d";
  grid-gap: 8px;
}
.gfi20{grid-area: a;}
.gfi21{grid-area: b;}
.gfi22{grid-area: c;}
.gfi23{grid-area: d;}

.grid-form-order-5{
  display: grid;
  text-align: center;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-areas: "a a a b c";
  grid-gap: 8px;
  width: 95%;
}
.grid-form-order-6{
  display: grid;
  text-align: center;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-areas: "a b b b c";
  grid-gap: 8px;
  width: 95%;
}

.gfi30{grid-area: a;}
.gfi31{grid-area: b;}
.gfi32{grid-area: c;}
.gfi33{grid-area: a;}
.gfi34{grid-area: b;}
.gfi35{grid-area: c; text-align: end;}

.gfi35 button{
  border: none;
  border-radius: 10px;
  margin-top: 10px;
  width: 95%;
}

.area-items{
  border: 1px solid gray;
  border-radius: 10px;
}
.table-concepts-orders{
  width: 100%;
  margin-top: 10px;
}
.delete-orders-crud-form{
  width: 30px;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
}
.selec3oc{
  color: #0056b3;
}
.selec3oc p{
  color: #0056b3;
}
.resaltado-purchase-orders-a{
  background-color: rgb(155, 255, 168) !important;
}
.resaltado-purchase-orders-c{
  background-color: rgb(217, 217, 217) !important;
}

.resaltado-purchase-orders-p{
  background-color: rgb(255, 243, 155) !important;
}

.gfi21 select, .gfi22 select{
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.actions-bottons-purchaseo{
  width: 25px;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
}

/* section de confirmar la oredn de compra */
.content-dashboard-order{
  width: 95%;
  margin: 0 auto;
  text-align: center;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 20px;
  grid-template-areas: "a b";
}
.content-sction-dashboard-a{
  grid-area: a;
}
.content-sction-dashboard-b{
  grid-area: b;
  width: 100%;
  position: relative;
}
.content-sction-dashboard-b iframe{
  width: 100%;
  height: 80%;
  border: none;
}
.content-sction-dashboard-a iframe{
  width: 100%;
  height: 80vh;
  border: none;
  margin-top: 68px;
}


.info-supplier{
  text-align: left;
  width: 95%;
  margin: 0 auto;
  border: 1px solid gray;
  padding: 5px;
  border-radius: 10px;
}
.info-supplier p{
  padding-bottom: 0px;
  margin-bottom: 4px;
  font-size: 15px;
}
.content-sction-dashboard-a h3{
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 10px;
}
.sections-pdfs-views{
  width: 50%;
  margin: 0 auto;
  text-align: center;
}
.sections-pdfs-views select{
  padding-top: 10px;
  padding-bottom: 10px;
}
.buttons-sections-validate{
  margin-top: 20px;
}
.buttons-sections-validate button{
  text-align: center;
}