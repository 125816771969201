.description-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-self: center;
    min-width: 400px;
    width: 600px;
    max-width: 100%;
    height: auto;
    max-height: 90%; 
    z-index: 0;
    text-align: center;
    padding: 5px;
    font-size: 1.0em;
  }

  .description-content{
    justify-content: first baseline;
    border-style: outset;
    border-width: 5px;
    padding: 10px;
  }