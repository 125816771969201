.TableConsultarTodo {
  margin: 0 auto;
  width: 100%;
  font-size: 14px !important;
  white-space: normal !important;
}

/* CUERPO TABLA */
.TableConsultarTodo td:nth-child(13),
.TableConsultarTodo td:nth-child(14),
.TableConsultarTodo td:nth-child(15) {
  text-align: center;
}

.TableConsultarTodo td:nth-child(12) {
  text-align: right;
}

.TableConsultarTodo td:nth-child(5){
  text-align: center;
}

.TableConsultarTodo thead th:nth-child(7){
  width: 100px !important;
}

.TableConsultarTodo thead th:nth-child(4),
.TableConsultarTodo thead th:nth-child(16){
  white-space: normal !important;
  width: 100px !important;
  max-width: 100px !important;
}

.TableConsultarTodo td:nth-child(4),
.TableConsultarTodo td:nth-child(16){
  white-space: normal !important;
  width: 100px !important;
  max-width: 100px !important; 
 }

.TableConsultarTodo td:nth-child(7){

 white-space: normal !important;

}

.TableConsultarTodo td:nth-child(7),
.TableConsultarTodo td:nth-child(8) {
  text-align: justify;
}

.cancel {
  cursor: pointer;
}


.TableConsultarTodo thead th:nth-child(1),
.TableConsultarTodo td:nth-child(1){
  white-space: normal !important;
  width: 50px !important;
  max-width: 50px !important;
  text-align: center;
}

.TableConsultarTodo thead th,
.TableConsultarTodo td{
  white-space: normal !important;
  width: auto !important;
  max-width: auto !important;
}





.div1c .fi03 {
  width: 50%;
}

.fi04{
  width: 250px!important;
}


.dimencionboton {
  width: 10%;
}

.filtrosBusqueda {
  display: none;
}

.div1c {
  float: left;
  margin-top: 36px;
  padding-top: 30px;
}
.div2c {
  float: left;
  margin-top: 34px;
  padding-top: 30px;
}
.div3c {
  float: left;
  margin-top: 36px;
  padding-top: 30px;
}
.div4c {
  float: left;
  margin-top: 36px;
  padding-top: 30px;
  padding-right: 40px;
}
.div5c {
  float: left;
  margin-top: 34px;
  padding-top: 30px;
}

.filtroRFC {
  width: 70%;
}

.filtroBP {
  width: 120%;
}

.expCSV {
  cursor: pointer;
}


.centermodaldescarga{
  text-align: center;
  padding-top: 250px;
}




.TableConsultarTodo2 {
  margin: 0 auto;
  width: 100% !important;
}
.TableConsultarTodo2 .sticky-table-table{
  width: 60%;
}

  
.TableConsultarTodo2 thead tr div{
  color: #000000 !important;
  font-weight: bold;
  text-align: center;
}

.TableConsultarTodo2 td:nth-child(1),
.TableConsultarTodo2 td:nth-child(2){
  text-align: center;
  color: #000000 !important;
  font-weight: bold;
}
.TableConsultarTodo2 td:nth-child(3){
  color: #000000 !important;
  font-weight: bold;
  /* min-width: 250px; */
  text-align: justify;
  width: 40% !important;
}

.TableConsultarTodo2 td:nth-child(4),
.TableConsultarTodo2 td:nth-child(5),
.TableConsultarTodo2 td:nth-child(6){
  color: #000000 !important;
  font-weight: bold;
  text-align: right;
}


.TableConsultarPPD td:nth-child(5),
.TableConsultarPPD td:nth-child(9),
.TableConsultarPPD td:nth-child(11){
text-align: center;
}

.TableConsultarPPD td:nth-child(8){
text-align: left;
}

.TableConsultarPPD td:nth-child(6),
.TableConsultarPPD td:nth-child(12){
text-align: right;
}



.modalCancelR{
  max-width: 70% !important;
}

.contenerMCancel{
  margin: 0 auto;
  width: 90%;
  
}

.contentMCancel{
  padding-left: 2%;
}



.contenerMCancel {
  width: 90%;
  margin: 0 auto;
  padding-right: 4%;
}
.bp-1 {
  grid-area: cp1;
}
.bp-2 {
  grid-area: cp2;
}
.bp-3 {
  grid-area: cp3;
}
.bp-4 {
  grid-area: cp4;
}
.bp-5 {
  grid-area: cp5;
}
.bp-6 {
  grid-area: cp6;
}

.bp-bus1{
  grid-area: npbus1;
  padding-top: 10px;
}
.bp-bus2{
  grid-area: npbus2;
  padding-top: 10px;

}
.bp-bus3{
  grid-area: npbus3;
}


.contenerMCancel {
  display: grid;
  grid-template-areas: "cp1 cp1 cp1 cp1 cp1 cp1"
                       "cp2 cp2 cp2 cp2 cp2 cp2"
                       "cp3 cp3 cp3 cp3 cp13 cp13"
                       "cp4 cp4 cp4 cp5 cp5 cp5";
  gap: 10px;
}


.containerCosultCancel{
  width: 70%;
}

.containerCosultCancel {
  position:absolute;
  display: grid;
  grid-template-areas: "npbus1 npbus1 npbus2 npbus2 npbus3 npbus3";
  gap: 10px;
}

.contanerBtnCancelR{
  width: 90%;
  margin: 0 auto;
  padding-right: 4%;
}
.contanerBtnCancelR {
  position:absolute;
  display: grid;
  grid-template-areas: "cp4 cp4 cp4 cp5 cp5 cp5";
  gap: 10px;
}




.contanerBtnRelacionar{
  width: 90%;
  margin: 0 auto;
  padding-right: 4%;
}
.contanerBtnRelacionar {
  position:relative;
  display: grid;
  grid-template-areas: "cp4 cp4 cp4 cp5 cp5 cp5";
  gap: 10px;
}









.contenerControllerCFDI {
  width: 85%;
}
.inv-1 {
  grid-area: inv1;
}
.inv-2 {
  grid-area: inv2;
}
.inv-3 {
  grid-area: inv3;
}
.inv-4 {
  grid-area: inv4;
}
.inv-5 {
  grid-area: inv5;
}
.inv-6 {
  grid-area: inv6;
}
.inv-7 {
  grid-area: inv7;
}
.inv-8 {
  grid-area: inv8;
}


.bp-1f {
  grid-area: cp1;
  padding-top: 15px;
}
.bp-2f {
  grid-area: cp2;
  padding-top: 15px;
}
.bp-3f {
  grid-area: cp3;
  padding-top: 10px;
}
.bp-4f {
  grid-area: cp4;
  padding-top: 15px;
}
.bp-5f {
  grid-area: cp5;
  padding-top: 15px;
}
.bp-6f {
  grid-area: cp6;
  padding-top: 10px;
}
.bp-7f {
  grid-area: cp7;
}
.bp-8f {
  grid-area: cp8;
}
.contenerControllerReport{
  display: grid;
  grid-template-areas: "cp1 cp2";
  padding-bottom: 10px;
  gap: 10px;
  z-index: 10;
  margin: 0 auto;
  width: 90%;
  cursor: pointer;
}


.contenerControllerCFDI {
  position: absolute;
  display: grid;
  grid-template-areas: "inv1 inv2 inv3 inv4 inv5 inv6 inv7 inv8";
  gap: 10px;
  z-index: 10;
  cursor: pointer;
}

input{
  width: 100% !important;
}



.contanerActionImg {
  width: 90%;
  margin: 0 auto !important;
}
.fat-1 {
  grid-area: cp1;
  width: 100%;
}
.fat-2 {
  grid-area: cp2;
}
.fat-3 {
  grid-area: cp3;
}
.fat-4 {
  grid-area: cp4;
}
.fat-5 {
  grid-area: cp5;
}
.fat-6 {
  grid-area: cp6;
}
.fat-7 {
  grid-area: cp7;
}


.contanerActionImg {
  display: grid;
  grid-template-areas: "cp1 cp2"
                       "cp3 cp4"
                       "cp5 cp6"
                       "cp7 cp8";
  gap: 10px;
  z-index: 20;
  cursor: pointer;
}

.cancelIMG{
  width: 20px !important;
  max-width:none !important;
  cursor: pointer;
}

.cancelIMG2{
  width: 20px !important;
  max-width:none !important;
  cursor: pointer;
}



