
/* Tabla de promociones */

.tablepromop{
  margin-top: -60px;
}

.TablePromotions .sticky-table-table{
    width: 100%;
  }
  .TablePromotions{
    margin: 0 auto;
    width: 95% !important;
    font-size: 17px;
  }
  .TablePromotions .titletable {
    margin-top: -30px;
    margin-bottom: -50px;
  }
  .TablePromotions td:nth-child(1){
    text-align: center;
  }
  .TablePromotions td:nth-child(2){
    padding-right: 30px;
    width: 9%;
  }
  .TablePromotions td:nth-child(3){
    text-align: center;
  }
  .TablePromotions td:nth-child(4){
    text-align: left;
    white-space: normal;
    max-width: 100px;
  }
  .TablePromotions td:nth-child(5),
  .TablePromotions td:nth-child(6),
  .TablePromotions td:nth-child(7),
  .TablePromotions td:nth-child(8),
  .TablePromotions td:nth-child(10){
    text-align: center;
  }
  .TablePromotions td:nth-child(9){
    text-align: right;
  }
  
  /* Tabla desplegable de promociones */
  .prodpromotionsubtable { 
    width: 60%;
  }
  
  
  /* Tabla de agregar productos */
  /* Tbla de productos del modal al crear una promocion */
  .subtablepromotions label {
    font-size: 17px;
  }
  .subtablepromotions input {
    height: 50px;
  }
  .subtablepromotions select {
    height: 20px;
  }
  .subtablepromotions{
    margin: 0 auto;
    width: 85% !important;
  }
  .subtablepromotions .sticky-table-table{
    width: 100%;
  }
  .subtablepromotions .sticky-table-table select{
    height: 20px;
  }
  .subtablepromotions td:nth-child(1){
    text-align: center;
  }
  .subtablepromotions td:nth-child(2){
    text-align: left;
  }
  .subtablepromotions td:nth-child(3),
  .subtablepromotions td:nth-child(4){
    text-align: center;
  }
  .subtablepromotions td:nth-child(5) input{
    height: 20px;
  }
  .addpointsalecss input{
    height: 50px;
    font-size: 18px;
  }
  .addpointsalecss select{
    height: 20px;
    font-size: 18px;
  }
  .addpointsalecss .inputpos{
    height: 70px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 10px;
  }


  
.subtablepromotions input{
    border:1px solid #A5A5A5;
    font-size: 18px;
  }
  .subtablepromotions select{
    border: 1px solid #A5A5A5;
    font-size: 18px;
  }
  .subtablepromotions label{
    background-color: white;
    font-size: 20px !important;
    color: black;
  }
  
  
  .gridpromo1{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 20% 20% 20% 20% 15%;
    grid-template-areas:"a a a b b";
    grid-gap: 10px;
  
  }
  .gridpromo2{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 20% 20% 20% 15% 20%;
    grid-template-areas:"a a b b c";
    grid-gap: 10px;
  }
  .gridpromo3{
    padding-top: 10px;
    margin: 0 6%;
    display: grid;
    width: 90%;
    grid-template-columns: 19% 29% 9% 19% 19%;
    grid-template-areas:"a a b b b";
    grid-gap: 10px;
  }
  
  .promocampo1{grid-area: a;}
  .promocampo2{grid-area: b;}
  .promocampo3{grid-area: a;}
  .promocampo4{grid-area: b;}
  .promocampo5{grid-area: c;}
  .promocampo6{grid-area: a;}
  .promocampo7{grid-area: b;}


  .promocampo5 input[type=checkbox]{
    width: 20px !important;
    height: 20px;
  }


  .formulariodescuentos input[type=checkbox]{
    width: 20px !important;
    height: 20px;
}
  
  @media only screen and (max-width: 720px) {
    
    .gridpromo1{
      grid-template-areas:
      "a a a a a"
      "b b b b b";
    } 
    .gridpromo2{
      grid-template-areas:
      "a a a a a"
      "b b b b b"
      "c c c c c";
    }
    .gridpromo3{
    margin: 0 8%;
    grid-template-areas:
    "a a a a a"
    "b b b b b";
  }
  
  .promocampo5{
    text-align: justify;
    padding-top: -40px;
    margin-top: -10px;
  }
  
  }