.tour-div h1{
    font-size: 20px;
    font-weight: bold;
}
.tour-div .tour-text{
    font-size: 18px;
    text-align: justify;
}
.tour-div .tour-acciones{
    padding-top: 20px;
    font-size: 19px;
    font-weight: bold;
    color: green;
}
.waving {
    animation: waving 1s linear infinite;
    transform-origin: center;
  }
  
  @keyframes waving {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-60deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }