/*CUERPO DE LA TABLA DETALLES DASHBOARD CLIENTES*/

.tableDashboard td:nth-child(1){
  width: auto;
  min-width: 150px !important;
}
.tableDashboard td:nth-child(2){
  width: auto;
  min-width: 150px;
}

.tableDashboard td:nth-child(2),
.tableDashboard td:nth-child(3),
.tableDashboard td:nth-child(4),
.tableDashboard td:nth-child(5),
.tableDashboard td:nth-child(6),
.tableDashboard td:nth-child(7),
.tableDashboard td:nth-child(8),
.tableDashboard td:nth-child(9),
.tableDashboard td:nth-child(10),
.tableDashboard td:nth-child(11),
.tableDashboard td:nth-child(12),
.tableDashboard td:nth-child(13),
.tableDashboard td:nth-child(14){
  text-align: right !important;
}





.DahsboardAlert {
  max-width: 90% !important;
  margin: 0 auto;
  
}


.dashTable table{
  margin: 0 3%;
  display: inline-table !important;
}

.TableConsultarTodo2 td:nth-child(7),
.TableConsultarTodo2 td:nth-child(8),
.TableConsultarTodo2 td:nth-child(9){

font-weight: bold;
}

.ModalBonus{
  max-width: 685px;
  max-height: 100%;
}

.ModalDetails{
  max-width:1022px;
  max-height: 100%;
}

/*Cuerpo de la tabla Sales*/

.TableSalesDashboard td:nth-child(2){
  text-align: left;
}


/*Cuerpo de la tabla Detail Sales*/

.TableSalesDetail{
white-space: normal !important;
    text-overflow: ellipsis;
    overflow: hidden;
}

.TableSalesDetail td:nth-child(2),
.TableSalesDetail td:nth-child(3),
.TableSalesDetail td:nth-child(4),
.TableSalesDetail td:nth-child(5),
.TableSalesDetail td:nth-child(6),
.TableSalesDetail td:nth-child(7),
.TableSalesDetail td:nth-child(8),
.TableSalesDetail td:nth-child(9){
  font-weight: 100 !important;
  text-align: center;
}


.TableSalesDetail thead th:nth-child(2),
.TableSalesDetail thead th:nth-child(6){
  max-width: 100px;
}

.TableSalesDetail thead th:nth-child(3),
.TableSalesDetail thead th:nth-child(7),
.TableSalesDetail thead th:nth-child(8){
  max-width: 150px;
}

.TableSalesDetail thead th:nth-child(5){
  max-width: 500px !important;
}

.TableSalesDetail td:nth-child(5){
  max-width: 500px !important;
  text-align: left;
}
.TableSalesDetail td:nth-child(4),
.TableSalesDetail td:nth-child(7),
.TableSalesDetail td:nth-child(9){
  text-align: left;
}



/*Cuerpo de la tabla Table Detail TIket*/

.TableDetailTIket{
  white-space: normal !important;
  margin: 0 auto !important;
  width: 95%;
}


.TableDetailTIket thead th:nth-child(2),
.TableDetailTIket thead th:nth-child(3),
.TableDetailTIket thead th:nth-child(4),
.TableDetailTIket thead th:nth-child(5){
  max-width: 200px !important;
  white-space: normal !important;

}

.TableDetailTIket thead tr div{
  color: white !important;
}

.TableDetailTIket td:nth-child(2),
.TableDetailTIket td:nth-child(3)
.TableDetailTIket td:nth-child(4)
.TableDetailTIket td:nth-child(5){
  white-space: normal !important;

}
.TableDetailTIket td:nth-child(1),
.TableDetailTIket td:nth-child(2),
.TableDetailTIket td:nth-child(3),
.TableDetailTIket td:nth-child(4),
.TableDetailTIket td:nth-child(5){
  font-weight: 100;
  text-align: center;
  
}
.TableDetailTIket td:nth-child(6){
  text-align: right;
  font-weight: 100;
}


.saleDetailTikect{
  max-width:850px;
  max-height: 100%;
}


.TableDetailTIketDetail thead tr div{
  color: rgb(0, 0, 0) !important;
}


.TableDetailTIketDetail td:nth-child(1),
.TableDetailTIketDetail td:nth-child(2),
.TableDetailTIketDetail td:nth-child(3),
.TableDetailTIketDetail td:nth-child(4),
.TableDetailTIketDetail td:nth-child(5),
.TableDetailTIketDetail td:nth-child(6),
.TableDetailTIketDetail td:nth-child(7){
  font-weight: 100 !important;
}


/*Table BOnus*/
.TableBonus2{
  width: 100% !important;
}



.TableBonus2 td:nth-child(2),
.TableBonus2 thead  div th:nth-child(2){
  text-align: center !important;
  width: 150px !important
}
.TableBonus td:nth-child(2),
.TableBonus td:nth-child(3),
.TableBonus td:nth-child(4),
.TableBonus td:nth-child(7),
.TableBonus td:nth-child(8),
.TableBonus td:nth-child(9){
  text-align: left;
  font-weight: 100;

}

.TableBonus td:nth-child(1),
.TableBonus td:nth-child(2),
.TableBonus td:nth-child(5),
.TableBonus td:nth-child(6){
  text-align: center;
  font-weight: 100;

}

.TableBonus thead th:nth-child(7),
.TableBonus thead th:nth-child(2),
.TableBonus thead th:nth-child(5),
.TableBonus thead th:nth-child(6){
  max-width: 100px;
}

.TableDetailBonus{
  margin: 0 auto;
  width: 95%;
}

.TableDetailBonus thead tr div{
  color: white !important;
}

.TableDetailBonus td:nth-child(3){
  text-align: right;
  font-weight: 100;
}

.TableDetailBonus td:nth-child(1),
.TableDetailBonus td:nth-child(2),
.TableDetailBonus td:nth-child(3),
.TableDetailBonus td:nth-child(4),
.TableDetailBonus td:nth-child(5),
.TableDetailBonus td:nth-child(6){
  font-weight: 100 !important;
}




.TableProducts td:nth-child(1),
.TableProducts td:nth-child(2),
.TableProducts td:nth-child(3),
.TableProducts td:nth-child(4),
.TableProducts td:nth-child(5),
.TableProducts td:nth-child(6),
.TableProducts td:nth-child(7),
.TableProducts td:nth-child(8),
.TableProducts td:nth-child(9){
  font-weight: 100 !important;
}




.sk-fading-circle {
  margin: 30px auto;
  width: 32px;
  height: 32px;
  position: relative;

}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 3px;
  height: 9px;
  background-color: #000;
  border-radius: 1.5px;
  animation: sk-circleFadeDelay 994ms infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
          transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
          transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
          transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
          transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
          transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
          transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
          transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
          transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
          transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
          transform: rotate(300deg); 
}
.sk-fading-circle .sk-circle12 {
          transform: rotate(330deg); 
}
.sk-fading-circle .sk-circle2::before {
          animation-delay: -911ms; 
}
.sk-fading-circle .sk-circle3::before {
          animation-delay: -828ms; 
}
.sk-fading-circle .sk-circle4::before {
          animation-delay: -745ms; 
}
.sk-fading-circle .sk-circle5::before {
          animation-delay: -662ms; 
}
.sk-fading-circle .sk-circle6::before {
          animation-delay: -579ms; 
}
.sk-fading-circle .sk-circle7::before {
          animation-delay: -496ms; 
}
.sk-fading-circle .sk-circle8::before {
          animation-delay: -413ms; 
}
.sk-fading-circle .sk-circle9::before {
          animation-delay: -330ms;
}
.sk-fading-circle .sk-circle10::before {
          animation-delay: -247ms;
}
.sk-fading-circle .sk-circle11::before {
          animation-delay: -164ms;
}
.sk-fading-circle .sk-circle12::before {
          animation-delay: -83ms;
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0.15; }
  40% { opacity: 0.5; } 
}

.theaddash{
  z-index: 1;
}

.gtTqsE{
  display:unset;
}