

.breadcrumbs-general {
    text-align: left;
    margin: 0 auto;
    width: 100%;
    font-size: 18px;
    margin-bottom: 15px;
    position: relative;
    z-index: 150;
    margin-left: 25px;
  }
  
  .breadcrumbs-general a {
    text-decoration: none;
    color: black !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .breadcrumbs-general a:hover {
    text-decoration: underline;
    background-color: rgb(26, 55, 77);
    color: white !important;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    cursor: pointer;
  }
  
  .breadcrumbs-general span {
    color: gray;
    padding-left: 10px;
    padding-right: 10px;
  }
  