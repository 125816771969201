.grid_real_sales{
    margin: 0 auto;
    display: grid;
    width: 95%;
    grid-template-columns: 15% 10% 10% 15%;
    grid-template-areas:
        "a b c d";
    grid-gap: 10px;
    align-items: end;
    padding-bottom: 10px;
}

.realsales1{grid-area: a;}
.realsales2{grid-area: b;}
.realsales3{grid-area: c;}
.realsales4{grid-area: d;}

.realsales4 p{
    padding-bottom: 10px;
    font-size: 23px;
    text-align: left;
}


.custom-label-gps {
    color: #333;
    padding: 5px;
    font-size: 10px;
    font-weight: bold;
}

.grid_real_sales label{
    font-size: 20px;
    color: black;
}

.realsales1 {
    padding-bottom: 12px;
}
.realsales1 input{
    height: 58px;
    font-size: 17px;
    color: black;
}
.realsales2 select{
    font-size: 17px;
    color: black;
}
.realsales3{
    padding-bottom: 7px;
}
.realsales3 button{
    height: 58px;
    font-size: 17px;
}

.tableventastreal table{
    margin: 0 2%;
    width: 70%;
    border: none;
}
.tableventastreal table td{
    text-align: left;
}
.tableventastreal table td p{
    display: inline-block;
    margin-left: 10px;
    font-size: 20px;
}

.TableSalesTimeReal .sticky-table-table{
   width: 100%;
}
.SubTableSalesTimeReal .sticky-table-table{
    width: 100%;
}
.SubTableSalesTimeReal{
    margin: 0 auto;
    width: 95% !important;
}
.TableSalesTimeReal{
    margin: 0 auto;
    width: 95% !important;
}
.TableSalesTimeReal td:nth-child(1),
.TableSalesTimeReal td:nth-child(2),
.TableSalesTimeReal td:nth-child(3),
.TableSalesTimeReal td:nth-child(4),
.TableSalesTimeReal td:nth-child(6),
.TableSalesTimeReal td:nth-child(7),
.TableSalesTimeReal td:nth-child(8),
.TableSalesTimeReal td:nth-child(10){
    text-align: center;
    max-width: 100px;
}
.TableSalesTimeReal td:nth-child(4){
    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.TableSalesTimeReal td:nth-child(5){
    text-align: left;
}

.TableSalesTimeReal td:nth-child(7){
    overflow: hidden;
    white-space: normal;
  /* text-overflow: ellipsis; */
}
.TableSalesTimeReal td:nth-child(9){
    text-align: center;
}
.griddetailsTR{ 
    margin: 0 auto;
    display: grid;
    width: 95%;
    grid-template-columns: 40% 28% 28%;
    grid-template-areas:
        "a a a"
        "b c c ";
    grid-gap: 10px;
}
.itemdetails1{grid-area: a;}
.itemdetails2{grid-area: b;}
.itemdetails3{grid-area: c;}

.griddetailsTR input{
    height: 55px;
    font-size: 17px;
    color: black;
}
.griddetailsTR label{
    font-size: 20px;
    color: black;
}
.SubTableSalesTimeReal .titletable{
 margin-top: 20px;
 margin-bottom: -40px;
}

.SubTableSalesTimeReal .sindetails{
    margin-top: 50px;
    font-size: 22px;
}

.SubTableSalesTimeReal td:nth-child(1),
.SubTableSalesTimeReal td:nth-child(3){
    text-align: center;
}
.SubTableSalesTimeReal td:nth-child(2){
    text-align: left;
}
.SubTableSalesTimeReal td:nth-child(4){
    text-align: center;
}
.detailstitle{
    padding-bottom: 20px;
}
.labeltextarea{
    position: absolute;
    background-color: white;
    margin-left: 20px;
    margin-top: -10px;
    font-size: 17px !important;
}

.notadetails {
    width: 100%;
    margin-left: 1%;
    padding: 10px;
    height: 100px;
    border-radius: 5px;
    border: 2px solid rgb(198, 198, 198);
    font-size: 16px;
}