@import './variables';

@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}

* {
    font-family: 'Roboto', sans-serif;
}

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
}

hr {
    margin: 0px;
    flex-shrink: 0;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: black;
}
 
p, h1, h2, h3, h4, h5, h6, div {
    color: $primaryText;
}