$primary: #5664d2;
$primaryLight: #99a2e4;
$primaryLighter: #EEEFFA;
$primaryDark: #333c7e;

$secondary: #ffffff;
$secondaryLight: #ffffff;
$secondaryDark:#ffffff;

$neutralDarker: #6b778c;
$neutralDark: #e0e0e0;
$neutral: #f3f6f8;
$neutralLight: #f3f6f8;

$nav-width: 256px;
$sideDetail-width: 456px;

$primaryText: #4e4e4e;