.saleDetailTikect{
    width: 80% !important;
    max-width: 95% !important;
}
.saleDetailTikect .sticky-table-table{
    width: 100%;
}
.table-detalles-sales-nd{
    width: 95%;
    margin: 0 auto;
}
.table-detalles-sales-nd .sticky-table-table{
    width: 100%;
}
.success-msg h3{
    font-size: 20px;
    text-align: center;
}
.TableSalesDetail div{
    width: 100% !important;
    margin: 0 auto !important;
}
.TableSalesDetail div .sticky-table-table{
    width: 100%;
}

.TableSalesDetail div td:nth-child(3){
    width: 30px !important;
    text-align: center;
}