.addrulesSE input{
    font-size: 20px;
    height: 58px;
    border: 1px solid rgb(104, 103, 103);
}
.addrulesSE label{
    font-size: 20px;
    color: black;
    background-color: white;
}
.addrulesSE select{
    font-size: 18px;
    border: 1px solid rgb(104, 103, 103);
}

.gridrule1{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-template-areas:
    "a a a a b b";
    grid-gap: 10px;
}
.rule1{grid-area: a;}
.rule2{grid-area: b;}

.gridrule2{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-template-areas:
    "a a b b c c";
    grid-gap: 10px;
}

.rule3{grid-area: a;}
.rule4{grid-area: b;}
.rule5{grid-area: c;}

.gridrule3{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-template-areas:
    "a a b b c c";
    grid-gap: 10px;
}

.rule6{grid-area: a;}
.rule7{grid-area: b;}
.rule8{grid-area: c;}

.tableRules .sticky-table-table{
    width: 100%;
}
.TableRules td:nth-child(1){
    text-align: center;
    min-width: 100px;
}
.TableRules td:nth-child(2){
    text-align: left;
    max-width: 500px;
    overflow: hidden;
    white-space: normal;
}
.TableRules{
    margin: 0 auto;
    width: 95% !important;
    margin-top: -50PX;
}
.TableRules .titletable{
   margin-top: -30px;
   margin-bottom: -50px;
}
.TableRules td:nth-child(3),
.TableRules td:nth-child(4),
.TableRules td:nth-child(5),
.TableRules td:nth-child(6),
.TableRules td:nth-child(7){
    text-align: center;
}
.TableRules td:nth-child(8),
.TableRules td:nth-child(9){
    text-align: right;
}
.gendoc{
  padding-left: 5%;
  padding-right: 2%;
  font-size: 13px;
  text-align: justify;
}

@media only screen and (max-width: 480px) {
    .gridrule1{
        grid-template-areas:
        "a a a a a a"
        "b b b b b b";
    }
    .gridrule2{
        grid-template-areas:
        "a a a a a a"
        "b b b b b b"
        "c c c c c c";
    }
    .gridrule3{
        grid-template-areas:
        "a a a a a a"
        "b b b b b b"
        "c c c c c c";
    }
}