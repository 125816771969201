.tabledetailsinvoices{
    width: 100%;
    margin: 0 auto;
}
.tabledetailsinvoices .sticky-table-table{
    width: 80%;
}
.tabledetailsinvoices td:nth-child(1){
    min-width: 150px;
}

.tabledetailsinvoices td:nth-child(3),
.tabledetailsinvoices td:nth-child(4),
.tabledetailsinvoices td:nth-child(5)
{
    text-align: right;
}
