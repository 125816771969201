.containerCutBox {
  display: grid;
  grid-template-areas: "expen1 expen2 expen3 expen4 expen5 expen8 expen7";
  gap: 10px;
  position: absolute;
  width: 90%;
  z-index: 1;
}

.containerCutBox020 {
  width: 90%;
  margin: 0 auto;
  display: grid;
  padding-top: 20px;
  padding-left: 2.5%;
  gap: 10px;
  position: absolute;
  grid-template-areas: "expen1 expen2 expen3 expen4 expen5 expen8 expen7";
}

.containerMovements {
  width: 100%;
  margin: 0 auto;
  display: grid;
  padding-top: 20px;
  padding-left: 2.5%;
  gap: 10px;
  position: relative;
  grid-template-areas: "expen1 expen2 expen3 expen4 expen5 expen8 expen7";
}

.dateffcutbox {
  width: 90% !important;
  height: 40px;
  margin: 0 auto;
}

.fExpense {
  width: 100%;
}

.fExpense2 {
  width: 100%;
  height: 35px !important;
}
/*generarExpense-1 expen-6... expen-7 */
.generarExpense {
  width: 89%;
  margin: 0 auto;
}

.generarCutbox,
.generarCutboxAlert {
  width: 90%;
  margin: 0 auto;
}
.expen-5 {
  grid-area: expen5;
}

.expen-5n {
  grid-area: expen5n;
  padding-top: 13px;
}
.expen-6 {
  grid-area: expen6;
  padding-top: 13px;
}
.expen-7 {
  grid-area: expen7;
}

.expen-8 {
  grid-area: expen8;
}
.expen-9 {
  grid-area: expen9;
}
.expen-10 {
  grid-area: expen10;
}
.expen-11 {
  grid-area: expen11;
}

.generarExpense {
  display: grid;
  grid-template-areas: "expen6 expen7";
  gap: 10px;
}

.generarCutbox {
  display: grid;
  grid-template-areas: "expen5n expen6 expen7 expen8";
  gap: 10px;
}

.generarCutboxAlert {
  display: grid;
  width: 80%;
  padding-right: 5%;
  grid-template-areas: "expen7";
}

.generarCutboxConfig {
  width: 90%;
  margin: 0 auto;
}

.generarCutboxConfig {
  display: grid;
  grid-template-areas:
    "expen5n expen5n expen5n expen6 expen6 expen6"
    "expen7 expen7 expen7 expen8 expen8 expen8 "
    "expen10 expen10 expen10 expen11 expen11 expen11 ";
  gap: 10px;
  padding-right: 5%;
}

.TableGTotalCutBox td:nth-child(1) {
  text-align: left;
}
.TableGTotalCutBox td:nth-child(2),
.TableGTotalCutBox td:nth-child(3),
.TableGTotalCutBox td:nth-child(4),
.TableGTotalCutBox td:nth-child(5),
.TableGTotalCutBox td:nth-child(6),
.TableGTotalCutBox td:nth-child(7),
.TableGTotalCutBox td:nth-child(8) {
  text-align: right;
}

.TableCutBoxx td:nth-child(2),
.TableCutBoxx td:nth-child(3),
.TableCutBoxx td:nth-child(4),
.TableCutBoxx td:nth-child(5) {
  text-align: center;
}

.TableCutBoxx td:nth-child(7),
.TableCutBoxx td:nth-child(8),
.TableCutBoxx td:nth-child(9),
.TableCutBoxx td:nth-child(10),
.TableCutBoxx td:nth-child(11),
.TableCutBoxx td:nth-child(12),
.TableCutBoxx td:nth-child(13),
.TableCutBoxx td:nth-child(14) {
  text-align: right;
}
.TableGTotalCutBox thead tr th {
  color: white !important;
}

.TableGTotalCutBox thead {
  background-color: #3f87a6 !important;
}

/* .TableGTotalCutBox tbody td:hover{
  background: #259cfd;
} */

.TableGTotalCutBox tbody tr:hover {
  background: #cccccc;
}

.headerAction {
  color: white;
  font-weight: bold;
}

.TableSaleCutBox thead th:nth-child(3) {
  max-width: 150px !important;
}

.TableSaleCutBox td:nth-child(3) {
  max-width: 150px;
}

.TableSaleCutBox td:nth-child(7),
.TableSaleCutBox td:nth-child(8),
.TableSaleCutBox td:nth-child(9),
.TableSaleCutBox td:nth-child(10),
.TableSaleCutBox td:nth-child(11) {
  text-align: right;
}

.TableSaleCutBox td:nth-child(5),
.TableSaleCutBox td:nth-child(12),
.TableSaleCutBox td:nth-child(13),
.TableSaleCutBox td:nth-child(14),
.TableSaleCutBox td:nth-child(15),
.TableSaleCutBox td:nth-child(16),
.TableSaleCutBox td:nth-child(17),
.TableSaleCutBox td:nth-child(18),
.TableSaleCutBox td:nth-child(19),
.TableSaleCutBox td:nth-child(20) {
  text-align: center;
}

@media only screen and (max-width: 1440px) {
  .TableSaleCutBox thead tr div {
    font-size: 12px;
  }
  .TableSaleCutBox td {
    font-size: 12px;
  }
}

.containerExportC {
  float: right;
  display: grid;
}
.expen-66 {
  grid-area: "expen-66";
  padding-top: 13px;
}
.containerExportC {
  display: grid;
  grid-template-areas: "expen6 expen-66";
  gap: 10px;
}

.centecricle {
  margin: 0 auto;
}

.iconPublicFacture {
  width: 44px;
  cursor: pointer;
}

.contanerFIltersTableSelect {
  margin: 0 auto;
  width: 90%;
  display: grid;
  gap: 10px;
  background-color: aliceblue;
}

.filter-1 {
  grid-area: cp1;
}
.filter-2 {
  grid-area: cp2;
}
.filter-3 {
  grid-area: cp3;
}
.filter-4 {
  grid-area: cp4;
}
.filter-5 {
  grid-area: cp5;
}
.filter-6 {
  grid-area: cp6;
}
.filter-7 {
  grid-area: cp7;
}
.filter-8 {
  grid-area: cp8;
}
.filter-9 {
  grid-area: cp9;
}
.filter-10 {
  grid-area: cp10;
}
.filter-11 {
  grid-area: cp11;
}
.filter-12 {
  grid-area: cp12;
}
.filter-13 {
  grid-area: cp13;
}
.filter-14 {
  grid-area: cp14;
}
.filter-15 {
  grid-area: cp15;
}
.filter-16 {
  grid-area: cp16;
}
.filter-17 {
  grid-area: cp17;
}
.filter-18 {
  grid-area: cp18;
}
.filter-19 {
  grid-area: cp19;
}
.filter-20 {
  grid-area: cp20;
}

.contanerFIltersTableSelect {
  grid-template-areas:
    "cp1 cp1  cp1   cp1  cp1"
    "cp2 cp3  cp4   cp5  cp6"
    "cp7  cp8  cp9  cp10 cp11"
    "cp12 cp13 cp14 cp15 cp16"
    "cp17 cp18 cp19 cp20 cp21"
    "cp22 cp23 cp24 cp25 cp26"
    "cp27 cp28 c219 cp30 cp31";
}

.tableFixHead {
  overflow: auto;
  width: 100%;
  height: 650px;
}

.tableFixHeadDashboard {
  overflow: auto;
  width: 100%;
  height: 650px !important;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}
.tableFixHeadDashboard thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.tableFixHead2 {
  overflow: auto;
  width: 100%;
  height: 450px !important;
}

.tableFixHead2 thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background-color: #3f87a6;
}

.cutBoxModal2 {
  max-width: 900px !important;
}

.ContainerTableCutBox2 {
  max-height: 100% !important;
}

.imgcancelsale {
  width: 25px !important;
  cursor: pointer !important;
}
.TableSaleCutBox td {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.title-model {
  position: absolute;
  width: 100%;
}

.title-model h1 {
  font-size: 25px !important;
  font-weight: bold;
}
