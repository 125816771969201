.informationcompany {
    width: 100%;
    table-layout: fixed;
}
.informationcompany td .titulo{
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.informationcompany td .texto{
    font-size: 18px;
}
.visibleb{
  float: right;
  padding-top:20px;
  margin-left: -20px;
  z-index: 1;
}


/* .ui.checkbox input[type=checkbox]{
    width: 20px;
    height: 20px;
    position: absolute;
} */

.pestañac3 input[type=checkbox]{
    width: 15px !important;
    height: 15px;
}
.candado{
  float: right;
  width: 10%;
  text-align: right;
  height: 40px;
  padding-top: 0px;
  position: relative;
  z-index: 10;
}
.candado img{
  width: 40px;
}
.candado img:hover{
  cursor: pointer;
}

.informationcompany td{
    padding: 10px;
    width: 50% !important;
    padding-right: 10px;
    font-size: 1px;
    padding-bottom: 20px;
    text-align: left;
    background-color: white !important;
}

.updatecompany{
    width: 90%;
    margin: 0 auto;
}
.updatecompany input{
    height: 60px;
    font-size: 18px;
    border: 1px solid black;
}
.updatecompany input:disabled{
  font-size: 18px;
  color: black;
  border: 1px solid black;
}

.updatecompany select{
    height: 26px;
    font-size: 18px;
    border: 1px solid black;
}
.updatecompany label{
    font-size: 20px;
    color: black;
    background-color: white;
}



.labelcheck{
    padding-top: 10px;
}

.pestañac3 div div{
    display: inline-flex;
    align-items: center;
    padding: 5px;
}
.pestañac3 div div label{
    background-color: transparent;
}



.compgrid01{
    margin-left: 2%;
    display: grid;
    width: 95%;
    height: 87px;
    grid-template-columns: 50% 50% ;
    grid-template-rows: 10px 20px;
    grid-template-areas:
        "a b "
}
.comp1{grid-area: a;}
.comp2{grid-area: b;}

.compgrid02{
    margin-left: 2%;
    display: grid;
    width: 95%;
    height: 87px;
    grid-template-columns: 20% 20% 20% 20% ;
    grid-template-rows: 10px 20px;
    grid-template-areas:
        "a b c d e"
}
.comp3{grid-area: a;}
.comp4{grid-area: b;}
.comp5{grid-area: c;}
.comp6{grid-area: d;}
.comp7{grid-area: e;}

.compgrid03{
    margin-left: 2%;
    display: grid;
    width: 95%;
    height: 87px;
    grid-template-columns: 50% 20% 30% ;
    grid-template-rows: 10px 20px;
    grid-template-areas:
        "a b c"
}
.comp8{grid-area: a;}
.comp9{grid-area: b;}
.comp10{grid-area: c;}
.comp10 input{     
    height: 20px;
    overflow: hidden;
}
.comp10 input:disabled{
    height: 58px !important;
}

.compgrid04{
    margin-left: 2%;
    display: grid;
    width: 95%;
    height: 87px;
    grid-template-columns: 50% 20% 30%;
    grid-template-rows: 10px 20px;
    grid-template-areas:
        "a b c"
}
.comp11{grid-area: a;}
.comp12{grid-area: b;}
.comp13{grid-area: c;}

.compgrid05{
    margin-left: 2%;
    display: grid;
    width: 95%;
    height: 87px;
    grid-template-columns: 40% 60%;
    grid-template-rows: 10px 20px;
    grid-template-areas:
        "a b"
}
.comp14{grid-area: a;}
.comp15{grid-area: b;}

.compgrid06{
    margin-left: 1%;
    display: grid;
    width: 95%;
    height: 87px;
    grid-template-columns: 25% 20% 38% 15%;
    grid-template-rows: 10px 20px;
    grid-template-areas:
       "a b c d"
}
.comp16{grid-area: a;}
.comp17{grid-area: b;}
.comp18{grid-area: c;}
.comp19{grid-area: d;}


.compgrid07{
    margin-left: 2%;
    display: grid;
    width: 95%;
    height: 87px;
    grid-template-columns: 20% 20% 20% 40%;
    grid-template-rows: 10px 20px;
    grid-template-areas:
       "a b c d"
}
.comp20{grid-area: a;}
.comp21{grid-area: b;}
.comp22{grid-area: c;}
.comp23{grid-area: d;}

.compgrid08{
    margin-left: 2%;
    display: grid;
    width: 95%;
    height: 87px;
    grid-template-columns: 100%;
    grid-template-rows: 10px 20px;
    grid-template-areas:
       "a"
}
.comp24{grid-area: a;}

.compgrid09{
    margin-left: 2%;
    display: grid;
    width: 95%;
    height: 87px;
    grid-template-columns: 20% 80%;
    grid-template-rows: 10px 20px;
    grid-template-areas:
       "b b"
}
.comp26{grid-area: b;}

.compgrid10{
    margin-left: 2%;
    display: grid;
    width: 95%;
    height: 87px;
    grid-template-columns: 20% 80%;
    grid-template-rows: 10px 20px;
    grid-template-areas:"a b"
}
.comp27{grid-area: a;}
.comp28{grid-area: b;}
.compgrid10b{
    margin-left: 2%;
    display: grid;
    width: 95%;
    height: 87px;
    grid-template-columns: 70% 30%;
    grid-template-rows: 10px 20px;
    grid-template-areas:"a b"
}
.comp29{grid-area: a;}
.comp29b{grid-area: b;}

.compgrid11{
    margin-left: 2%;
    display: grid;
    width: 95%;
    height: 87px;
    text-align: left;
    grid-template-columns: 30% 36% 34%;
    grid-template-rows: 10px 20px;
    grid-template-areas:
    "a b c"
}
.comp30{grid-area: a;}
.comp31{grid-area: b;}
.comp32{grid-area: c;}
.compgrid12{
    margin-left: 2%;
    display: grid;
    width: 95%;
    height: 87px;
    text-align: left;
    grid-template-columns: 30% 36% 34%;
    grid-template-rows: 10px 20px;
    grid-template-areas:
    "a b c"
}
.comp33{grid-area: a;}
.comp34{grid-area: b;}
.comp35{grid-area: c;}

.compgrid13{
    margin-left: 2%;
    display: grid;
    width: 95%;
    height: 87px;
    text-align: left;
    grid-template-columns: 30% 36% 34%;
    grid-template-rows: 10px 20px;
    grid-template-areas:
    "a b c"
}
.comp36{grid-area: a;}
.comp37{grid-area: b;}
.comp38{grid-area: c;}

.compgrid14{
    margin-left: 2%;
    display: grid;
    width: 95%;
    height: 87px;
    text-align: left;
    grid-template-columns: 30% 36% 34%;
    grid-template-rows: 10px 20px;
    grid-template-areas:
    "a b c"
}
.comp39{grid-area: a;}
.comp40{grid-area: b;}
.comp41{grid-area: c;}

.compgrid15{
    margin-left: 2%;
    display: grid;
    width: 95%;
    height: 87px;
    text-align: left;
    grid-template-columns: 30% 36% 34%;
    grid-template-rows: 10px 20px;
    grid-template-areas:
    "a b c"
}
.comp42{grid-area: a;}
.comp43{grid-area: b;}
.comp44{grid-area: c;}

.compgrid16{
    margin-left: 2%;
    display: grid;
    width: 95%;
    height: 87px;
    text-align: left;
    grid-template-columns: 30% 36% 34%;
    grid-template-rows: 10px 20px;
    grid-template-areas:
    "a b c"
}
.comp45{grid-area: a;}
.comp46{grid-area: b;}
.comp47{grid-area: c;}

.compgrid17{
    margin-left: 2%;
    display: grid;
    width: 95%;
    height: 87px;
    text-align: left;
    grid-template-columns: 50% 50%;
    grid-template-rows: 10px 20px;
    grid-template-areas:
    "a b"
}
.comp48{grid-area: a;}
.comp49{grid-area: b;}

.pestc1c2{
    width: 100%;
}
.updatecompany h2{
 padding-bottom: 10px;
}
.navegacioncompany div div button{
    background-color: white;
    height: 58px;
    min-width: 300px;
    border: 2px solid rgb(190, 190, 190);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
}
.pestc1c2 span{
    font-size: 20px !important;
}
.navegacioncompany div div svg {
    height: 30px;
    width: 60px;
}

.pcc1 {
    padding-top: 10px;
    width: 50%;
    height: 40px;
    font-size: 18px;
    }
.pcc2 {
    padding-top: 20px;
    height: 40px;
    width: 50%;
    font-size: 18px;
    }

.pcc1, .pcc2, .pcc3{
 cursor: pointer;
 display: inline-block;
 border: 1px solid black;
 border-top-left-radius: 10px;
 border-top-right-radius: 10px;
}
.pestañac1,
.pestañac2,
.pestañac3{
    border: 2px solid rgb(190, 190, 190);
    border-radius: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
}


.compgridd1{
    margin: 0 auto;
    display: grid;
    width: 95%;
    grid-template-columns: 20% 20% 15% 15% 25%;
    grid-template-areas:
    "a a b c d";
    grid-gap: 10px;
    /* justify-content: center; */
}
.compgridd2{
    margin: 0 auto;
    display: grid;
    width: 95%;
    grid-template-columns: 20% 20% 15% 27% 13%;
    grid-template-areas:
    "a b c d e";
    grid-gap: 10px;
}
.compgridd3{
    margin: 0 auto;
    display: grid;
    width: 95%;
    grid-template-columns: 20% 20% 15% 20% 20%;
    grid-template-areas:
    "a a a b c";
    grid-gap: 10px;
}




.compaddress1{grid-area: a;};
.compaddress2{grid-area: b;};
.compaddress3{grid-area: c;};
.compaddress4{grid-area: d;};

.compaddress5{grid-area: a;}
.compaddress6{grid-area: b;}
.compaddress7{grid-area: c;}
.compaddress8{grid-area: d;}
.compaddress9{grid-area: e;}

.compaddress10{grid-area: a;}
.compaddress11{grid-area: b;}
.compaddress12{grid-area: c;}
