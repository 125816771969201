.addrulesSE input{
    font-size: 20px;
    height: 58px;
    border: 1px solid rgb(104, 103, 103);
}
.addrulesSE label{
    font-size: 20px;
    color: black;
    background-color: white;
}
.addrulesSE select{
    font-size: 18px;
    border: 1px solid rgb(104, 103, 103);
}
.tablewarehousepp{
    margin-top: -50px;
}

.gridwarehouse1{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 33% 30% 30%;
    grid-template-areas:
    "a b b";
    grid-gap: 10px;
}
.gridwarehouse2{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 33% 30% 30%;
    grid-template-areas:
    "a a a";
    grid-gap: 10px;
}
.gridwarehouse3{
    margin: 0 auto;
    display: grid;
    width: 90%;
    text-align: left;
    grid-template-columns: 33% 30% 30%;
    grid-template-areas:
    "a b c";
    grid-gap: 10px;
}



.wh1{grid-area: a;}
.wh2{grid-area: b;}
.wh3{grid-area: a;}
.wh4{grid-area: a;}
.wh5{grid-area: b;}
.wh6{grid-area: c;}

.TableAlmacenes .sticky-table-table{
    width: 100%;
}
.TableAlmacenes td:nth-child(1){
    text-align: center;
    min-width: 100px;
}
.TableAlmacenes td:nth-child(2){
    text-align: center;
}

.TableAlmacenes td:nth-child(3){
    text-align: center;
}
.TableAlmacenes td:nth-child(4){
    text-align: left;
}
.TableAlmacenes td:nth-child(5){
    text-align: center;
}
.TableAlmacenes td:nth-child(6){
    text-align: center;
}



.TableAlmacenes{
    margin: 0 auto;
    width: 95% !important;
}
.TableAlmacenes .titletable{
   margin-top: -30px;
   margin-bottom: -50px;
}


.gendoc{
  padding-left: 5%;
  padding-right: 2%;
  font-size: 13px;
  text-align: justify;
}

@media only screen and (max-width: 480px) {
    .gridwarehouse1{
        grid-template-areas:
        "a a a"
        "b b b";
    }
    .gridwarehouse3{
       grid-template-areas:
        "a a a" 
        "b b b"
        "c c c";
    }
    
}

  