.TableGoals .sticky-table-table{
    width: 100%;
}
.TableGoals{
    margin: 0 auto;
    width: 90% !important;
}
.TableGoals .titletable{
    margin-bottom: -40px;
}

.TableGoals td:nth-child(1){
    width: 100px;
    text-align: center;
}
.TableGoals td:nth-child(1) img{
    width: 30px;
    height: 30px;
}
.TableGoals td:nth-child(2){
    text-align: center;
}
.TableGoals td:nth-child(3){
    text-align: right;
}

/* input de metas */
.input-goal{
    display: grid;
    width: 90%;
    grid-template-columns: 40% 20%;
    grid-template-areas: "a b";
    grid-gap: 10px;
    margin-bottom: -55px;
    margin-top: 10px;
}
.grid-imp-1{
    grid-area: a;
    position: relative;
}
.grid-imp-1 input{
    height: 40px;
}
.grid-imp-1 label{
    position: absolute;
    margin-top: -12px;
    margin-left: 10px;
}

.grid-imp-2{
    grid-area: b;
}
.grid-imp-2 button{
     width: 100%;
     height: 100%;
     margin-top: 0px;
}


.grid-goal{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 25% 35% 30%;
    grid-template-areas: "a b c";
    grid-gap: 10px;
}
.goal1 label, .goal2 label{
    font-size: 20px;
}

.goal1{
    grid-area: a;
}
.goal2{
    grid-area: b;
}
.goal3{
    grid-area: c;
    position: relative;
}
.goal3 input{
    margin-left: 10px;
    margin-top: 12px;
    height: 54px !important;   
}
.goal3 label{
    position: absolute;
    margin-left: 17px;
    font-size: 15px;
}


.goal2 input{
    height: 54px;
}

@media only screen and (max-width: 480px) {
    .grid-goal{
        grid-template-areas: 
        "a a"
        "b b";
    }
    
  }