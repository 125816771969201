#map{
    width: 97%;
    margin: 0 auto;
    height: 600px;
}
.container{
    height: 100;
}
.simbologiadia{
    width: 100%;
}
.simbologiadia table {
    border: none;
}

.simbologiadia table td label{
    font-size: 17px;
    /* font-weight: bold; */
    padding-right: 5px;
}

.simbologiadia table td button{
    width: 30px;
    height: 30px;
    border-radius: 20px;
    padding-left: 5px;
}
.simbologiadia table td {
    padding-right: 20px;
}


.simbologia{
    width: 100%;   
    /* height: 32px; */
}

.simbologia table {
    border: none;
}

.simbologia table td label{
    font-size: 17px;
    /* font-weight: bold; */
    padding-right: 5px;
}

.simbologia table td button{
    width: 30px;
    height: 30px;
    border-radius: 20px;
    padding-left: 5px;
}
.simbologia table td {
    padding-right: 20px;
}


.botonsfilt{
    margin: 0 auto;
    display: grid;
    width: 95%;
    grid-template-columns: 10% 10% 10% 8% 15% 12% 7% 13% 10%;
    grid-template-areas: "a b c d e f g h i";
    grid-gap: 10px;
}

.ff1{grid-area: a;}
.ff2{grid-area: b;}
.ff3{grid-area: c;}
.ff4{grid-area: d;}
.ff5{grid-area: e;}
.ff6{grid-area: f;}
.ff7{grid-area: g;}
.ff8{grid-area: h;}
.ff9{grid-area: i;}

.ff4 button, .ff6 button, .ff8 button, .ff9 button{
    margin-top: 10px;
    width: 90%;
    border-radius: 5px;
}
.ff5 input{
  margin-top: 12px;
  width: 90%;
  border-radius: 5px;
}
.ff5 input[type="file"] {
    width: 150px;
    font-size: 18px;
}


.consultposgps{
 padding-top: 10px;
 font-size: 17px;
}
.ff7 img{
  height: 50px;
  width: 50px;
}
.ff7 img:hover{
  cursor: pointer;
}


.tablaRgps .sticky-table-table{
    width: 100%;
    margin-top: -10px; 
}
.tablaRgps {
    margin: 0 auto;
    width: 95% !important;
}

.tablaRgps .containerExport div{
    position: relative;
    top: 0;
    left: 0;
    z-index: 100 !important;
}
.tablaRgps td:nth-child(1){
    text-align: center;
}
.tablaRgps td:nth-child(2),
.tablaRgps td:nth-child(3),
.tablaRgps td:nth-child(4){
    text-align: left;
}
.tablaRgps td:nth-child(5),
.tablaRgps td:nth-child(6){
    text-align: center;
}

.inforwindowpos{
    text-align: left;
}
.inforwindowpos h3{
    font-size: 15px;
}



@media only screen and (max-width: 720px) {
  .botonsfilt{
    grid-template-areas: 
        "a a b b c c d d d"
        "e e f f g h h i i";
  }
}