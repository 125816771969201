

/* Tabla de roles  */
.TableRoles{
    margin: 0 auto;
    width: 90% !important;
  }
  .TableRoles .sticky-table-table{
    width: 100%;
  }
  .TableRoles .titletable{
    margin-top: -30px;
    margin-bottom: -30px;
  }
  .TableRoles{
    width: 95% !important;
    margin: 0 auto;
    margin-top: -50px;
  }
  .TableRoles .sticky-table-table{
    width: 100%;
  }
  .TableRoles td:nth-child(1){
    width: 10%;
    text-align: center;
  }
  .TableRoles td:nth-child(1) img{
    max-width: 30px;
    max-height: 30px;
  }
  
  .TableRoles td:nth-child(2),
  .TableRoles td:nth-child(3){
    text-align: left;
  }
  
  .addpointsalecss label{
    font-size: 22px;
  }
  
  
  