.paymentreport-container{
    width: 100% !important;
}
.search_payment_report{
    display: grid;
    grid-template-columns: 10% 10% 10% 20% 50%;
    grid-template-areas: "a b c d e";
    width: 95%;
    margin: 0 auto;
    grid-gap: 10px;
    position: relative;
    z-index: 150;
}
.fil1{
    grid-area: a;
}
.fil2{
    grid-area: b;
}

.fil1 input, .fil2 input{
    height: 40px !important;
}
.button-report{
    grid-area: c;
    height: 40px !important;
    text-align: left;
}
.filtro-report-payment{
    grid-area: d;
}
.button-report button{
    border-radius: 5px;
}

.table_report_payment{
    width: 95% !important;
    margin: 0 auto;
    margin-top: -50px;
}
.table_report_payment .sticky-table-table{
    width: 100% !important;
}
.table_report_payment th:nth-child(10),
.table_report_payment th:nth-child(11),
.table_report_payment th:nth-child(12),
.table_report_payment th:nth-child(13),
.table_report_payment th:nth-child(14)
{
    background-color: rgb(15, 15, 97);
}


.table_report_payment td{
    white-space: normal;
}

.table_report_payment td:nth-child(1),
.table_report_payment td:nth-child(2)
{
    text-align: center;
}
.table_report_payment td:nth-child(3){
    text-align: center;
    min-width: 200px;
    max-width: 250px;
}
.table_report_payment td:nth-child(4),
.table_report_payment td:nth-child(5)
{
    text-align: left;
}
.table_report_payment td:nth-child(6){
    text-align: center;
}
.table_report_payment td:nth-child(7),
.table_report_payment td:nth-child(8),
.table_report_payment td:nth-child(9)
{
    text-align: right;
}

.table_report_payment td:nth-child(10){
    text-align: center;
}
.table_report_payment td:nth-child(11){
    text-align: center;
    min-width: 200px;
    max-width: 250px;
}
.table_report_payment td:nth-child(12),
.table_report_payment td:nth-child(13),
.table_report_payment td:nth-child(14){
    text-align: right;
}