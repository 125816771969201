.container-zip-comp{
    width: 100%;
    height: auto;
}
.container-forms-inv{
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-areas: "a b";
    grid-gap: 10px;
}
.grid-zip-1{
    grid-area: a;
}
.grid-zip-2{
    grid-area: b;
    padding: 10px;
}
.grid-zip-2 button{
    height: 100%;
    border-radius: 10px;
}
.cargando-facturas{
    width: 90%;
    text-align: center;
}
.cargando-facturas{
    width: 90%;
    margin: 0 auto;
    padding-bottom: 20px;
}
.load-progress {
    width: 100%;
    height: 20px;
    background-color: #d2d1d1;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 20px;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #007bff;
    transition: width 0.5s ease-in-out; /* Animación de llenado */
  }
  .msj-zip{
    font-size: 18px;
  }
  .num-fac-comp
{
    font-size: 18px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 15px;
}